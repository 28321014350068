<template>
    <header class="max-w-xl w-full p-2">
        <h1 class="font-bold text-4xl text-yellow-400 p-1">Pokédex</h1>
        <slot />
    </header>
</template>

<script>
export default {
    name: 'ScreenTitle',
};
</script>

<style></style>
