<template>
    <svg viewBox="0 0 24 24" class="h-12 w-12 opacity-10 animate-spin">
        <use href="@/assets/img/pokeball.svg#svg" width="100%" height="100%" />
    </svg>
</template>

<script>
export default {
    name: 'LoadingPokeball',
};
</script>

<style></style>
