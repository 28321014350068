<template>
    <main class="flex flex-col justify-center items-center w-full">
        <slot />
    </main>
</template>

<script>
export default {
    name: 'Container',
};
</script>

<style></style>
